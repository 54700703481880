// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Users
export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_SAVE_SUCCESS = "USERS_SAVE_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const CLEAR_USER = "CLEAR_USER";
export const USER_EXPORT_SUCCESS = "USER_EXPORT_SUCCESS";

// Statistic
export const STATISTIC_GET_REQUEST = "STATISTIC_GET_REQUEST";
export const STATISTIC_GET_USER = "STATISTIC_GET_USER";
export const STATISTIC_GET_VEHICLE = "STATISTIC_GET_VEHICLE";
export const STATISTIC_GET_COUPON = "STATISTIC_GET_COUPON";
export const STATISTIC_GET_PAYMENT = "STATISTIC_GET_PAYMENT";
export const STATISTIC_GET_ERROR = "STATISTIC_GET_ERROR";

// States
export const STATE_GET_REQUEST = "STATE_GET_REQUEST";
export const STATE_GET_SUCCESS = "STATE_GET_SUCCESS";
export const STATES_GET_SUCCESS = "STATES_GET_SUCCESS";
export const ACTIVATE_STATE_SUCCESS = "ACTIVATE_STATE_SUCCESS";
export const STATES_GET_ERROR = "STATES_GET_ERROR";

// Coupon
export const COUPON_GET_REQUEST = "COUPON_GET_REQUEST";
export const COUPON_GET_SUCCESS = "COUPON_GET_SUCCESS";
export const COUPONS_GET_SUCCESS = "COUPONS_GET_SUCCESS";
export const COUPONS_EXPORT_SUCCESS = "COUPONS_EXPORT_SUCCESS";
export const COUPON_VALIDATE = "COUPON_VALIDATE";
export const COUPON_DELETE = "COUPON_DELETE";
export const COUPON_GET_ERROR = "COUPON_GET_ERROR";

// Blog
export const BLOG_GET_REQUEST = "BLOG_GET_REQUEST";
export const BLOGS_GET_SUCCESS = "BLOGS_GET_SUCCESS";
export const BLOG_GET_SUCCESS = "BLOG_GET_SUCCESS";
export const BLOG_SAVE = "BLOG_SAVE";
export const BLOG_EDIT = "BLOG_EDIT";
export const BLOG_GET_ERROR = "BLOG_GET_ERROR";

// Vehicles
export const VEHICLE_GET_REQUEST = "VEHICLE_GET_REQUEST";
export const VEHICLES_GET_SUCCESS = "VEHICLES_GET_SUCCESS";
export const VEHICLES_RESUME_GET_SUCCESS = "VEHICLES_RESUME_GET_SUCCESS";
export const VEHICLES_FILTER_IDS = "VEHICLES_FILTER_IDS";
export const VEHICLES_FILTER_STATES = "VEHICLES_FILTER_STATES";
export const VEHICLE_GET_SUCCESS = "VEHICLE_GET_SUCCESS";
export const VEHICLE_GET_ERROR = "VEHICLE_GET_ERROR";
export const VEHICLE_DELETE_REQUEST = "VEHICLE_DELETE_REQUEST";

// Events
export const EVENT_GET_REQUEST = "EVENT_GET_REQUEST";
export const EVENTS_GET_SUCCESS = "EVENTS_GET_SUCCESS";
export const EVENT_GET_SUCCESS = "EVENT_GET_SUCCESS";
export const EVENT_GET_ERROR = "EVENT_GET_ERROR";
export const EVENT_CLEAR = "EVENT_CLEAR";
export const EVENT_SAVE_SUCCESS = "EVENT_SAVE_SUCCESS";
export const EVENT_EDIT_SUCCESS = "EVENT_EDIT_SUCCESS";
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";

// Admin
export const ADMIN_GET_REQUEST = "ADMIN_GET_REQUEST";
export const ADMIN_GET_SUCCESS = "ADMIN_GET_SUCCESS";
export const ADMINS_GET_SUCCESS = "ADMINS_GET_SUCCESS";
export const ADMIN_SAVE_SUCCESS = "ADMIN_SAVE_SUCCESS";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_GET_ERROR = "ADMIN_GET_ERROR";
export const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";
export const CLEAR_ADMIN = "CLEAR_ADMIN";

// Payment
export const PAYMENT_GET_REQUEST = "PAYMENT_GET_REQUEST";
export const PAYMENTS_GET_SUCCESS = "PAYMENTS_GET_SUCCESS";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";
export const PAYMENT_GET_ERROR = "PAYMENT_GET_ERROR";

// Header
export const HEADER_TEXT = "HEADER_TEXT";


// Search
export const SEARCH_GET_REQUEST = "SEARCH_GET_REQUEST";
export const SEARCH_GET_SUCCESS = "SEARCH_GET_SUCCESS";
export const SEARCH_GET_ERROR = "SEARCH_GET_ERROR";

// DashBoard
export const DASHBOARD_GET_REQUEST = "DASHBOARD_GET_REQUEST";
export const DASHBOARD_GET_SUCCESS = "DASHBOARD_GET_SUCCESS";
export const DASHBOARD_NOTDRIVE_GET_SUCCESS = "DASHBOARD_NOTDRIVE_GET_SUCCESS";
export const DASHBOARD_VERIFICAION_GET_SUCCESS = "DASHBOARD_VERIFICAION_GET_SUCCESS";
export const DASHBOARD_GET_ERROR = "DASHBOARD_GET_ERROR";

// Filter
export const FILTER_GET_REQUEST = "FILTER_GET_REQUEST";
export const FILTER_GET_COUPONS_SUCCESS = "FILTER_GET_COUPONS_SUCCESS";
export const FILTER_GET_USERS_SUCCESS = "FILTER_GET_USERS_SUCCESS";
export const FILTER_GET_VEHICLE_SUCCESS = "FILTER_GET_VEHICLE_SUCCESS";
export const FILTER_GET_ERROR = "FILTER_GET_ERROR";

// DoubleAlert
export const DOUBLEALERT_GET_REQUEST = "DOUBLEALERT_GET_REQUEST";
export const DOUBLEALERT_GET_SUCCESS = "DOUBLEALERT_GET_SUCCESS";
export const DOUBLEALERT_SAVE_SUCCESS = "DOUBLEALERT_SAVE_SUCCESS";
export const DOUBLEALERT_GET_ERROR = "DOUBLEALERT_GET_ERROR";