import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import statisticReducer from "./statisticReducer";
import stateReducer from "./stateReducer";
import couponReducer from "./couponReducer";
import blogReducer from "./blogReducer";
import vehiclesReducer from "./vehiclesReducer";
import eventsReducer from "./eventsReducer";
import adminReducer from "./adminReducer";
import paymentReducer from "./paymentReducer";
import headerReduce from "./headerReducer";
import searchReducer from "./searchReducer";
import dashboardReducer from "./dashboardReducer";
import filterReducer from "./filterReducer";
import doubleAlertReducer from "./doubleAlertReducer.js";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  userReducer,
  statisticReducer,
  stateReducer,
  couponReducer,
  blogReducer,
  vehiclesReducer,
  eventsReducer,
  adminReducer,
  paymentReducer,
  headerReduce,
  searchReducer,
  dashboardReducer,
  filterReducer,
  doubleAlertReducer
});
